import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { color, wrapper, breakpoints } from '../../utils/style';
import Img from 'gatsby-image';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Parallax from '../../components/shared/Backgrounds/Parallax';
import Oval1 from '../../icons/svg/Backgrounds/Oval1';
import BottomBanner from '../../components/Content/Home/BottomBanner';
import ContentFromWp from '../../components/shared/ContentFromWP';

const WrapperDesktop = styled('div')`
  ${wrapper}
  .title-block-wrapper {
    position: relative;
    padding: 1px;
    background: ${color.azul};
    background-image: linear-gradient(to left, #57ffeb, #ff989c);
    border-radius: 100px;
    box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    margin: 40px 0 60px;
    .mobile-img {
      display: none;
    }
  }
  .left-img-wrap {
    width: 323px;
    position: absolute;
    left:0;
    bottom: 0;
    z-index: 1;
  }
  .right-img-wrap {
    width: 360px;
    position: absolute;
    top: 0;
    right:0;
    z-index: 1;
  }
  
  .central-block {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: ${color.white};
    background-image: linear-gradient(133deg, #fff9f8 18%, #effffd 85%);
    border-radius: 99px;
    padding: 30px 100px;
    height: 390px;
    .h1 {
      width: 450px;
      position: relative;
      margin: 0;
      font-size: 80px;
      line-height:1.12;
      text-transform: uppercase;
      z-index: 1;
    }
  }
  .title-bg-oval {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -150px 0 0 -150px;
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .central-block {
      .h1 {
        font-size: 50px;
        width: 40%;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    .title-block-wrapper {
      border-radius: 50px;
    }
    .left-img-wrap {
      width: 28%;
    }
    .right-img-wrap {
      width: 30%;
    }
    .central-block {
      height: 250px;
      border-radius: 49px;
      .h1 {
        font-size: 48px;
        width: 50%;
      }
    }
    .title-bg-oval {
      width: 150px;
      height: 150px;
      margin: -75px 0 0 -75px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .title-block-wrapper {
      margin: 20px 0 50px;
      .mobile-img {
        display: block;
        margin-bottom: 30px;
      }
    }
    .central-block {
      display: block;
      height: auto;
      padding: 5px 5px 30px;
      .h1 {
        width: 90%;
        margin:0 auto;
      }
    }
    .title-bg-oval, .left-img-wrap, .right-img-wrap {
      display: none;
    }
  }
  
`;

const PostContentWrapper = styled('ul')`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 30px;
  li {
    flex-basis: 32%;
    position: relative;
  }

  .item-link {
    position: relative;
    display: block;
    padding: 40px 0 0 40px;
    font-size: 21px;
    line-height: 1.52;

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50px 20px 20px 20px;
      width: calc(100% - 40px);
    }

    .gatsby-image-wrapper {
      border-radius: 50px 20px 20px 20px;
    }

    .post-title-block {
      padding: 319px 32px 33px;
      border-radius: 20px;
      background: ${color.white};
      box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
      color: ${color.black};
    }

    &:hover,
    &:active {
      text-decoration: none;
      .post-title-block {
        color: ${color.red};
        box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.12);
      }
    }
  }
  
  .lower-button-container {
    margin: 40px 0 0;
  }
  
  @media (max-width: ${breakpoints.lg}) {
    .item-link {
      .post-title-block {
        padding-top: 98%;
      }
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
    .lower-button-container {
      margin: 25px 0 0;
    }
    ul {
      margin-bottom: 5px;
    }
    li:first-of-type {
      flex-basis: 100%;
      margin-bottom: 20px;
      .item-link {
        padding: 20px 0 0 20px;
        .image-container {
          width: calc(100% - 20px);
        }
      }
    }
    li {
      flex-basis: 47%;
      margin-bottom: 20px;
      .item-link {
        padding: 20px 0 0 5px;
        font-size: 1rem;
        .post-title-block {
          padding: 100% 20px 20px;
        }
        .image-container {
          width: 100%;
        }
      }
    }
  }
`;


const createGroups = (pages, pageLength) =>
  pages
    .map((edge, index) => {
      return index % pageLength === 0
        ? pages.slice(index, index + pageLength)
        : null;
    })
    .filter(item => item);

let nextPageIndex = 1;
const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title) ?
    currentCategory.yoast_meta.yoast_wpseo_title : currentCategory.title + "- " + process.env.OWNER;
  const metaDesc = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc) ?
    currentCategory.yoast_meta.yoast_wpseo_metadesc : '';
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const girl = data.girl;
  const balls = data.balls;
  const homeBottomBanner = data.home.edges[0].node.acf?.bottom_banner;

  const pageLength = pageContext.pageLength;
  const groups = createGroups(data.allWordpressPost.edges, pageLength);
  const [items, setItems] = useState(groups[0].concat([]));
  //const submenuLinkTitle = (currentCategory.wordpress_id === 19) ? 'All blog' : currentCategory.name;

  const loadMore = (e, index) => {
    nextPageIndex = nextPageIndex + 1;
    setItems(items.concat(groups[index]));
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace('category/','')}
        key="seo"
        breadcrumbs={breadcrumbs}
      />
      <Parallax/>
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        {girl && balls ?
          <div className="title-block-wrapper">
            <div className="left-img-wrap">
              <Img fluid={girl.childImageSharp.fluid} alt="News Girl" fadeIn={false} loading='eager'/>
            </div>
            <div className="central-block">
              {currentCategory.acf?.category_image?.localFile &&
              <div className="mobile-img">
                <Img fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
                     alt="Lotto News Mobile" fadeIn={false} loading='eager'/>
              </div>
              }
              <Oval1 width={300} height={300} className="title-bg-oval"/>
              <h1 className="h1 a-center gradient">
                <span dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
              </h1>
            </div>
            <div className="right-img-wrap">
              <Img fluid={balls.childImageSharp.fluid} alt="News Lotto" fadeIn={false} loading='eager'/>
            </div>
          </div> :
          <h1 className="h1 a-center gradient">
            <span dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
          </h1>
        }

        {/*(currentCategory.acf?.category_image?.localFile) ?
          <SlickSlide img={currentCategory.acf.category_image.localFile}
                      altText={currentCategory.name} line2={currentCategory.name}/>

          : <NoFeaturedWrapper>
            <h1 className="rich-design">
              <span dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
            </h1>
          </NoFeaturedWrapper>
        */}
        <PostContentWrapper>
          {items.map(({ node }) => (
            <li key={node.label + node.slug}>
              <Link className="item-link" to={'/' + node.slug + '/'}>
                <div className="image-container">
                  {node.featured_media?.localFile?.childImageSharp ? (
                    <Img fluid={node.featured_media.localFile.childImageSharp.fluid}
                         alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title}
                         fadeIn={false} loading='eager'
                    />
                  ) : (
                    <Img
                      fluid={data.placeholder.childImageSharp.fluid}
                      alt={node.label} fadeIn={false} loading='eager'
                    />
                  )}
                </div>
                <div className="post-title-block">
                  <span className="post-title" dangerouslySetInnerHTML={{ __html: node.title }} />
                </div>
              </Link>
            </li>
          ))}
          {(groups.length > 1 && nextPageIndex <= groups.length - 1) &&
          <div className="a-center">
            <button className="button gradient-border-button"
                    onClick={(e) => loadMore(e, nextPageIndex)}>
              <span>โหลดมากขึ้น...</span>
            </button>
          </div>

          }
        </PostContentWrapper>
        {(groups.length > 1 && nextPageIndex <= groups.length - 1) &&
        <div className="a-center lower-button-container">
          <button className="button gradient-border-button"
                  onClick={(e) => loadMore(e, nextPageIndex)}>
            <span>โหลดมากขึ้น...</span>
          </button>
        </div>
        }
        {currentCategory.description &&
        <ContentFromWp>
          <div dangerouslySetInnerHTML={{ __html: currentCategory.description }}/>
        </ContentFromWp>
        }
        <BottomBanner banner={homeBottomBanner}/>
      </WrapperDesktop>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(
      filter: { wordpress_id: {eq: $wordpress_id} }
    ) {
      edges {
        node {
          id
          name
          slug
          description
          path
          wordpress_id
          link
            acf {
              category_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 500, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            yoast_meta {
              yoast_wpseo_title
              yoast_wpseo_metadesc
            }
          }
        }
    }
    allWordpressPost(
      sort: { fields:date, order: DESC },
        filter: {
          categories: {
            elemMatch: {
              wordpress_id: {eq:$wordpress_id}
            }
          }
        }
      ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
    girl: file(relativePath: { eq: "news/lottery_news_girl.png" }) {
      childImageSharp {
        fluid(maxWidth: 323, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    balls: file(relativePath: { eq: "news/lottery_news_balls.png" }) {
      childImageSharp {
        fluid(maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    home: allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          acf {
            bottom_banner {
              acf {
                slide_url
                slide_title
                slide_description
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1190, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }  
    }
  }  
`;
