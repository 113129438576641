import React, { memo } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { breakpoints } from '../../../utils/style';

const BottomBannerWrap = styled('div')`
  margin-bottom: 30px;
  .title-block {
    position: relative;
    margin: 3.5rem 0 1rem;
  }
  .h1 {
    margin: 0 auto 40px;
    text-align: center;
  }
  .banner-link {
    position: relative;
    display: block;
    border-radius: 20px;
    &:hover, &:active {
      text-decoration: none;
    }
    .gatsby-image-wrapper {
      border-radius: 20px;
      box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.05);
    }
    .button {
      position: absolute;
      bottom: 15px;
      right: 40px;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    position: static;
    .banner-link {
      .button {
        position: static;
      }
    }
    .button-wrapper {
      padding-top: 25px;
    }
    
  }  
  @media (max-width: ${breakpoints.sm}) {
    .h1 {
      margin-bottom: 0.7rem;
    }
  }
`;







const BottomBanner = ({ banner }) => {
  const bannerLink = banner.acf.slide_url ? banner.acf.slide_url : '/lottery/';

  return (
    <BottomBannerWrap>
      <div className="title-block">
        <h2 className="h1 gradient a-center">
          <span>ลองสล็อตยอดนิยม</span>
        </h2>
      </div>
      {!bannerLink.includes('http') ? <Link to={bannerLink} className="banner-link">
          <Img alt={banner.alt_text} fluid={banner.localFile.childImageSharp.fluid} />
          <div className="button-wrapper a-center">
            <span className="button gradient-border-button wider"><span>ลองตอนนี้</span></span>
          </div>
        </Link> :
          <a href={bannerLink} className="banner-link">
            <Img alt={banner.alt_text} fluid={banner.localFile.childImageSharp.fluid} />
            <div className="button-wrapper a-center">
              <span className="button gradient-border-button wider"><span>ลองตอนนี้</span></span>
            </div>
          </a>
      }
    </BottomBannerWrap>
  );
};

BottomBanner.propTypes = {
  banner: PropTypes.shape({
    alt_text: PropTypes.string,
    acf: PropTypes.shape({
      slide_description: PropTypes.string,
      slide_title: PropTypes.string,
      slide_url: PropTypes.string,
    }),
  }),
};

BottomBanner.defaultProps = {
  banner: {},
};

export default memo(BottomBanner);
